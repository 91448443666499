import { EditorSDK } from '@wix/platform-editor-sdk';
import { getChatComponentRef } from '../chat-editorsdk-helpers';
import { token } from '../../constants';

export const migrateToNewWidget = async (editorSDK: EditorSDK) => {
  const compRef = await getChatComponentRef(editorSDK);

  const publicData = await editorSDK.document.tpa.data.getAll<any, any>(token, {
    compRef,
  });

  const updatedGeneral = {
    ...publicData.APP?.general,
    ...publicData.COMPONENT?.general,
    widgetRedesign: true,
  };

  await editorSDK.document.tpa.data.set(token, {
    compRef,
    scope: 'COMPONENT',
    key: 'general',
    value: updatedGeneral,
  });
};
